/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/popup';
import 'semantic-ui-css/components/rating';

import $ from 'jquery';
import 'slick-carousel';

import 'sylius/ui/app';
import 'sylius/ui/sylius-api-login';
import 'sylius/ui/sylius-api-toggle';

import './sylius-add-to-cart';
import './sylius-address-book';
import './sylius-variant-images';
import './sylius-variants-prices';
import './sylius-auto-complete';

$(document).ready(() => {
    $('.popup-js').popup();

    $('.ui.search').autoComplete();

    $('.cart.button')
        .popup({
            popup: $('.cart.popup'),
            on: 'click',
        });

    $('.star.rating').rating({
        fireOnInit: true,
        onRate(value) {
            $('[name="sylius_product_review[rating]"]:checked').removeAttr('checked');
            $(`#sylius_product_review_rating_${value - 1}`).attr('checked', 'checked');
        },
    });

    $('#sylius_checkout_address_customer_email').apiToggle({
        dataType: 'json',
        method: 'GET',
        throttle: 1500,

        beforeSend(settings) {
            const email = $('#sylius_checkout_address_customer_email').val();

            if (email.length < 3) {
                return false;
            }

            /* eslint-disable-next-line no-param-reassign */
            settings.data = {
                email,
            };

            return settings;
        },

        successTest(response) {
            return $('#sylius_checkout_address_customer_email').val() === response.username;
        },
    }, $('#sylius-api-login-form'));

    $('#sylius-api-login').apiLogin({
        method: 'POST',
        throttle: 500,
    });

    $('#sylius-product-adding-to-cart').addToCart();

    $('.address-book-select').on('click touch', function () {
        $(this).parent().addressBook();
    })

    $(document).variantPrices();
    $(document).variantImages();

    $('body').find('input[autocomplete="off"]').prop('autocomplete', 'disable');

    $('.carousel').slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $('.carousel-left'),
        nextArrow: $('.carousel-right'),
        appendArrows: false
    });

    $('.hp-filter > a.item').on('click touch', function () {
        $(this).parent().find('a.item').removeClass('active');
        $(this).addClass('active');
        $.ajax({
            url: $(this).data('href'),
            method: "GET",
            context: this
        }).done(function (html) {
            $('#' + $(this).data('cible')).html(html);
        });
    });

    $('.check-moment').on('change', function () {
        const $this = $(':selected', $(this));
        const w = parseInt($this.data('w'));
        const date = new Date($this.val());
        const now = new Date();
        if (!parseInt($('#' + $(this).data('check-moment')).data('uniq'))) {
            $('#' + $(this).data('check-moment') + ' > option').each(function (i, e) {
                $(this).prop("disabled", false);
                if ((w === 0 && parseInt(e.value) !== 0) || (date.toDateString() === now.toDateString() && now.getHours() >= 11 && parseInt(e.value) === 2)) {
                    $(this).attr('disabled', 'disabled');
                    $(this).prop("selected", false);
                }
            });
        }
    });
    // OrderController->checkoutDeliveryTimeAction => moment->range OsKour
    // moment in form is useless
    $('.check-time').on('change', function () {
        const $this = $(':selected', $(this));
        $.getJSON({
            url: $('#' + $(this).data('check-time')).data('action'),
            data: {
                date: $('#' + $(this).data('date')).val(),
                moment: $('#' + $(this).data('check-moment')).val(),
            },
            context: this,
        }).done(function (hours) {
            const $hourSelect = $($(`#${$(this).data('check-time')}`).find('select')[0]);
            $hourSelect.find('option').remove();
            $.each(hours, (i, e) => {
                $hourSelect.append($('<option></option>').attr('value', parseInt(e) ? e : '').text(e));
            });
        });
    });
});

document.addEventListener('DOMContentLoaded', () => {
    $('.hp-filter').find('a.item:first').each(function() {
        $.ajax({
            url: $(this).data('href'),
            method: "GET",
            context: this
        }).done(function (html) {
            $('#' + $(this).data('cible')).html(html);
        });
    });
});
