// // In this file you can import assets like images or stylesheets
// //import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/entry.js';
//
import $ from 'jquery';
global.$ = $;
global.jQuery = $;
//
//
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-lightbox';
import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/shim/shim-semantic-ui';
//
import 'semantic-ui-css/semantic.css';
import 'lightbox2/dist/css/lightbox.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick';
// //
//import 'sylius/ui-resources/js/app';
import '../../../src/Sylius/Bundle/UiBundle/Resources/private/js/app';

import '../../../src/Sylius/Bundle/ShopBundle/Resources/private/js/app.js';
import '../../../src/Sylius/Bundle/ShopBundle/Resources/private/js/accessory/modal.js';
// import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/js/app';

import '../../../src/Sylius/Bundle/UiBundle/Resources/private/sass/main.scss';
import '../../../src/Sylius/Bundle/ShopBundle/Resources/private/sass/style.scss';
//
// // import '../../../vendor/sylius/sylius/src/Sylius/Bundle/ShopBundle/Resources/private/scss/style.scss';
// // //src/Sylius/Bundle/UiBundle/Resources/private/sass/main.scss
// // import 'sylius/ui-resources/img/avatar.png';
// // import 'sylius/ui-resources/img/logo.png';
// // import 'sylius/ui-resources/img/50x50.png';
// // import 'sylius/ui-resources/img/200x200.png';
// // import 'sylius/ui-resources/img/400x300.png';
//
// // import "./scss-files.js"
// //import "./js-files.js"
//
//
//
//
// //import './img/homepage-banner.jpg';
// //import './img/sylius-plus-banner.png';
