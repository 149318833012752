/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/dropdown';
import $ from 'jquery';

const parseKey = function parseKey(key) {
  return key.replace(/(_\w)/g, words => words[1].toUpperCase());
};

$.fn.extend({
  addressBook() {
    const element = this;
    const select = element.find('.address-book-select');
    const findByName = function findByName(name) {
    var el = select.parent().find(`[name*="[${parseKey(name)}]"]`);
      return el;
    };

    select.dropdown({
      forceSelection: false,

      onChange(name, text, choice) {
        // element.find('input:not([type="radio"]):not([type="checkbox"]), select:not([data-auto="off"])').each((index, input) => {
        //   $(input).val('');
        // });

        Object.entries(choice.data()).forEach(([property, value]) => {
          const field = findByName(property);
          field.val('');

          if (field.is('[type="radio"]') || field.is('[type="checkbox"]')) {
            field.prop('checked', false);
            field.filter(`[value="${value}"]`).prop('checked', true);
          } else {
            field.val(value);
          }
        });
      },
    });
  },
});
