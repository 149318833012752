import {
    showModalAccessories,
    manageSubmitForm,
    manageAccessoriesSelection,
    manageNavBarAccessories,
    generatePageAccessoriesByProduct
} from "./modalFunctions";

import {
    detectDevice
} from "../tools/detectDevice";

// Initialisation de la modal d'accessoires
$(document).ready(function () {
    let selectedAccessoriesQuantities = {};

    $('#submit-cart-type').click(function(){
        $('#sylius-product-adding-to-cart').submit();
    });

    $('#openAccessoriesModal').click(function () {
        let columnClass = detectDevice() === 'Desktop' ? 'ten wide column' : 'twelve wide column centered';
        let productQuantity = $('#sylius_add_to_cart_cartItem_quantity').val();

        productQuantity = (!productQuantity || parseInt(productQuantity) <= 0) ? 1 : parseInt(productQuantity);
        $('#columnsByDevice').addClass(columnClass);

        showModalAccessories();
        manageNavBarAccessories(productQuantity);
        generatePageAccessoriesByProduct(productQuantity, selectedAccessoriesQuantities);
        manageAccessoriesSelection(selectedAccessoriesQuantities);
        manageSubmitForm();
    });
});
