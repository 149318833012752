import {
    detectDevice
} from "../tools/detectDevice";

const fakeAccessoryCard = `<div class="ui card fake-accessory-card" style="visibility: hidden;"></div>`;

function AccessoryCard(accessory, quantity, detectDevice, productIndex) {
    this.accessory = accessory;
    this.quantity = quantity;
    this.detectDevice = detectDevice;
    this.productIndex = productIndex;
}

AccessoryCard.prototype.render = function () {
    const deviceClass = this.detectDevice === 'Desktop' ? 'eight wide column' : '';
    return `
        <div class="${deviceClass} accessory-section">
            <div class="ui card">
                <div class="content padding-content">
                    <div class="header">
                        <h5>${this.accessory.name}
                            <p class="right floated">${this.accessory.price}</p>
                        </h5>
                    </div>
                    <div class="padding-top-card-accessory">
                        <div title="${this.accessory.description}">${this.accessory.image}</div>
                        <div class="ui input right floated quantity-control">
                            <div class="ui icon button decrement-btn">
                                <i class="minus icon"></i>
                            </div>
                            <input readonly class="quantity-input" data-product-index="${this.productIndex}"
                                type="number" name="accessory[${this.accessory.id}]"
                                value="${this.quantity}" min="0" data-code="${this.accessory.code}">
                            <div class="ui icon button increment-btn">
                                <i class="plus icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
};

// Affiche la modal des accessoires
export function showModalAccessories() {
    $('#navAccessories a:first-child').trigger('click');
    $('#limitMessage').hide();
    $('.ui.longer.modal').modal('show');
}

// Gestion de la soumission du formulaire
export function manageSubmitForm() {
    $('#sylius-product-adding-to-cart').on('keydown', function(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    });

    $('#submit-modal-cart-type').click(function() {
        // empeche un clic multiple et si le bouton est désactivé ne rien faire
        if ($(this).prop('disabled')) {
            return;
        }

        // desactive le bouton d'envoi du form (empeche les soumissions multiples)
        $(this).prop('disabled', true);

        let form = $('#sylius-product-adding-to-cart');
        let productAccessories = {};

        $('.quantity-input').each(function (index) {
            let quantity = $(this).val();

            if (quantity > 0) {
                let accessoryId = $(this).attr('name').match(/\d+/)[0];
                let productIndex = $(this).data('product-index');

                // ajoute ou update l'accessoire
                productAccessories[productIndex] = productAccessories[productIndex] || {};
                productAccessories[productIndex][accessoryId] = quantity;
            }
        });

        // Nettoyage des champs existants d'accessoires avant d'ajouter les nouveaux
        Object.keys(productAccessories).forEach(productIndex => {
            Object.keys(productAccessories[productIndex]).forEach(accessoryId => {
                let inputName = `accessories[${productIndex}][${accessoryId}]`;

                $(`input[name="${inputName}"]`).remove();
                // champ caché avec la quantité et l'id de l'accessoire et l'index du product
                form.append(`<input style="display: none;" type="number" name="${inputName}" value="${productAccessories[productIndex][accessoryId]}">`);
            });
        });

        form.submit();
    });
}

// Gère la sélection des accessoires pour chaque produit
export function manageAccessoriesSelection(selectedAccessoriesQuantities) {
    const maxAccessories = 3;

    $(document).off('click', '.increment-btn');
    $(document).off('click', '.decrement-btn');

    $(document).on('click', '.increment-btn', function() {
        const WATER_BUBBLE_CODE = 1;
        let input = $(this).siblings('.quantity-input');
        let value = parseInt(input.val());
        let accessoryId = input.attr('name').match(/\d+/)[0];
        let accessoryCode = input.data('code');
        let productId = input.closest('.ui.tab').data('tab').split('_')[1];
        let productUniqueId = `product_${productId}`;

        if (accessoryCode === WATER_BUBBLE_CODE) {
            input.val(1);
            selectedAccessoriesQuantities[productUniqueId] = selectedAccessoriesQuantities[productUniqueId] || {};
            selectedAccessoriesQuantities[productUniqueId][accessoryId] = 1;
            $('#limitMessage').hide();
        } else {
            let totalAccessories = Object.keys(selectedAccessoriesQuantities[productUniqueId] || {})
                .filter(id => selectedAccessoriesQuantities[productUniqueId][id] > 0 && $('[name="accessory[' + id + ']"]').data('code') !== 1)
                .reduce((a, b) => a + selectedAccessoriesQuantities[productUniqueId][b], 0);

            if (totalAccessories < maxAccessories) {
                input.val(value + 1);
                selectedAccessoriesQuantities[productUniqueId] = selectedAccessoriesQuantities[productUniqueId] || {};
                selectedAccessoriesQuantities[productUniqueId][accessoryId] = (selectedAccessoriesQuantities[productUniqueId][accessoryId] || 0) + 1;
                $('#limitMessage').hide();
            } else {
                $('#limitMessage').show();
            }
        }
    });

    $(document).on('click', '.decrement-btn', function() {
        let input = $(this).siblings('.quantity-input');
        let value = parseInt(input.val());
        let accessoryId = input.attr('name').match(/\d+/)[0];
        let accessoryCode = input.data('code');
        let productId = input.closest('.ui.tab').data('tab').split('_')[1];
        let productUniqueId = `product_${productId}`;

        if (value > 0) {
            if (accessoryCode === 1) {
                input.val(0);
                delete selectedAccessoriesQuantities[productUniqueId][accessoryId];
            } else {
                input.val(value - 1);
                selectedAccessoriesQuantities[productUniqueId] = selectedAccessoriesQuantities[productUniqueId] || {};
                selectedAccessoriesQuantities[productUniqueId][accessoryId] = Math.max((selectedAccessoriesQuantities[productUniqueId][accessoryId] || 0) - 1, 0);
            }
        }
        $('#limitMessage').hide();
    });
}

// Gère la barre de navigation des accessoires
export function manageNavBarAccessories(productQuantity) {
    let navContent = '';
    let nav = $('#navAccessories');

    for (let i = 1; i <= productQuantity; i++) {
        navContent += `<a class="item ${i === 1 ? 'active' : ''}" data-tab="Product_${i}">Produit ${i}</a>`;
    }

    nav.html(`<div class="ui secondary menu">` + navContent + `</div>`);

    $('#navAccessories a').click(function() {
        $('#navAccessories a').removeClass('active');
        $(this).addClass('active');
    });

    $('.ui.menu .item').tab();
}

// Génère les pages d'accessoires par produit
export function generatePageAccessoriesByProduct(productQuantity, selectedAccessoriesQuantities) {
    let filteredAccessories = JSON.parse($('#accessoryData').html());
    let accessoryContent = $('#accessoryContent');

    accessoryContent.empty();

    for (let i = 1; i <= productQuantity; i++) {
        let accessoriesContainer = '<div class="ui grid">';
        let productUniqueId = `product_${i}`;

        // Si un seul accessoire on l'ajoute et on ajoute la fake card
        filteredAccessories.forEach((accessory) => {
            const quantity = selectedAccessoriesQuantities[productUniqueId] && selectedAccessoriesQuantities[productUniqueId][accessory.id] || 0;
            const card = new AccessoryCard(accessory, quantity, detectDevice(), i);
            const cardHTML = card.render();

            // accessoire réel
            accessoriesContainer += `<div class="eight wide column">
                ${cardHTML}
            </div>`;

            if (filteredAccessories.length === 1) {
                // fake accessoire (pour l'affichage dans la modal)
                accessoriesContainer += `<div class="eight wide column">${fakeAccessoryCard}</div>`;
            }
        });

        accessoriesContainer += '</div>';

        let tabContent = `<div class="ui tab ${i === 1 ? 'active' : ''}" data-tab="Product_${i}">
            ${accessoriesContainer}
        </div>`;
        accessoryContent.append(tabContent);
    }

    $('.ui.menu .item').tab();
}
