// Détection de l'appareil en fonction de la largeur de l'écran
export function detectDevice() {
    const MOBILE = 'Mobile';
    const DESKTOP = 'Desktop';
    const MOBILE_DESKTOP_LIMIT_PX = 1024;
    let width = $(window).width();
    let device;

    if (width <= MOBILE_DESKTOP_LIMIT_PX) {
        device = MOBILE;
    } else if (width > MOBILE_DESKTOP_LIMIT_PX) {
        device = DESKTOP;
    }

    return device;
}
