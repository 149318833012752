/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/search';
import $ from 'jquery';

$.fn.extend({
  autoComplete() {
    this.each((idx, el) => {
      const parent = $(el)
      const element = parent.find('input');
      const loadForEditUrl = element.data('load-url');
      const postcodeField = $('#'+element.data('postcode-field'));
      const cityField = $('#'+element.data('city-field'));
      $(el).search({
        apiSettings: {
          url: loadForEditUrl+'?term={query}'
        },
        maxResults: 100,
        minCharacters : 2,
        searchDelay: 250,
        onSelect: function(result, response) {
          postcodeField.val(result.data.zipCode).attr('data-value', result.data.zipCode);
          cityField.val(result.data.cityName).attr('data-value', result.data.cityName);
          parent.attr('data-valid', 1);
          $(el).search('hide results');
          return false;
        },
        onSearchQuery: function(query){
          parent.addClass("loading");
        },
        onResults: function (response){
          parent.removeClass("loading");
        },
        onResultsClose: function() {
          parent.removeClass("loading");
        },
       })
    });
  },
});
